import React from 'react';

import { main, img } from './404.module.scss';
import Wrapper from '../components/Wrapper/Wrapper';
import pageNotFoundSvg from '../assets/svg/page-not-found.svg';

const PageNotFound = () => (
  <Wrapper>
    <main id="container" className={`container is-fullwidth ${main}`}>
      <h1 className="title is-3 has-text-centered">Oops! Looks like this page does not exist...</h1>
      <img className={img} src={pageNotFoundSvg} alt="Illustration of a woman sitting on the number 404." />
    </main>
  </Wrapper>
);

export default PageNotFound;
